clear-both()
    clear: both

clear-fix()
    &:before,
    &:after
        content: " "
        display: table
    &:after
        clear: both

