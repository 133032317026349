@require '/src/dev'

// TODO: Alternatives to overflow-hidden grid(?)

.grid
    // overflow: hidden * Checking what consequences removing this has *
    clear-fix()
    
    &__unit
        float: left
